// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("@rails/activestorage").start();
require("channels");
require("slick-carousel");

import("js/toggle_menu");
import("js/credentials-slider");
import("js/office-slider");
import("js/smooth-anchor-scrolling");
import("js/close-alerts");
import("js/validate_iban");
import("js/toggle-password");
import("js/copy-to-clipboard");
import $ from "jquery";

window.jQuery = $;
window.$ = $;

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
